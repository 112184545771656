import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getReleaseNotesData, deleteReleaseNote } from '../services/service'
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReleaseNotesModal from './ReleaseNotesModal';
import CommonDeleteModal from './CommonDeleteModal';
import { Link } from 'react-router-dom';
import backarrow from "../assets/images/back-arrow.svg";
import parse from "html-react-parser";
import moment from "moment";
export function stripHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';

}
const ReleaseNote = () => {
    const queryClient = useQueryClient();
    const [editReleaseId, setEditReleaseId] = useState(null);
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowDataId, setSelectedRowDataId] = useState({});
    const [selectedRowNameData, setSelectedRowNameData] = useState({});

    let allReleaseNotes = useQuery("getAllReleaseNotesData", getReleaseNotesData);
    const releaseNoteData = allReleaseNotes?.data?.data || [];
    console.log("allEventEnqueryData", allReleaseNotes)
    // Set initial JSON data on component mount

    const handleClose = () => {
        setShow(false);
        setEditReleaseId(null);
    };
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setEditReleaseId(null)
        
    };
    const handleShow = () => setShow(true);
    const handleEdit = (noteId) => {
        console.log("noteId", noteId)
        setEditReleaseId(noteId);
        handleShow();
    };

    const handleInvalidateReleaseNote = () => {
        queryClient.invalidateQueries('getAllReleaseNotesData');
        // Invalidate the query to refetch restaurant types
        setEditReleaseId(null)
    };

    // useEffect(() => {

    // }, [releaseNoteData?.length]);

    const handleDelete = (rowData) => {
        setSelectedRowDataId(rowData.id);
        setSelectedRowNameData(rowData.version);
        setShowDeleteModal(true);
    };

    return (
        <>
            <div className="page-body ms-0">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        {/* <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link className="text-decoration-none" to="/manage-offers">
                                        Manage Offers
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Create Offer
                                </li>
                            </ol>
                        </nav> */}
                        <div className="left-header d-flex align-items-center mb-4">
                            <Link
                                to="/websites-list"
                                className="back-arrow d-inline-block me-2"
                            >
                                <img src={backarrow} alt="" />
                            </Link>
                            <h4 className="fw-normal m-0">Back To Website List</h4>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium">Release Notes</h5>
                                    <Button onClick={handleShow} className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2">
                                        Add new
                                    </Button>
                                </div>
                                <div className="card-body">
                                    <div className="tabledata">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Version</th>
                                                    <th>Description</th>
                                                    <th>Release Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {releaseNoteData?.map((note, index) => (
                                                    <tr key={note?.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{note?.version}</td>
                                                        <td className=''>{parse(note?.releaseDes)}</td>
                                                        <td>{note?.createdAt?moment(note?.createdAt).format("DD/MM/YYYY"):""}</td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <Button
                                                                    variant=''
                                                                    onClick={() => handleEdit(note.id)}
                                                                    className="btn-sm btn-theme text-white"
                                                                >
                                                                    <i className="fa fa-pencil m-0 opacity-100" aria-hidden="true"></i>
                                                                </Button>
                                                                <Button
                                                                    className="bg-danger text-white border-danger btn-sm btn-theme ms-1"
                                                                    onClick={() => handleDelete(note)}
                                                                >
                                                                    <i className="fa fa-trash m-0 opacity-100" aria-hidden="true"></i>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <ReleaseNotesModal
                show={show}
                handleClose={handleClose}
                invalidateData={handleInvalidateReleaseNote}
                releaseNoteData={releaseNoteData}
                editReleaseId={editReleaseId}
            />

            <CommonDeleteModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                rowDataId={selectedRowDataId}
                rowNameData={selectedRowNameData}
                onInvalidate={handleInvalidateReleaseNote}
                deleteMutation={deleteReleaseNote}
            />
        </>
    );
};

export default ReleaseNote;
