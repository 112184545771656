import React from "react";
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify';
import * as dataquery from "../pages/dataService/DataService";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Button, Modal } from 'react-bootstrap';

const CommonDeleteModal = ({show, handleClose, rowDataId, rowNameData,deleteMutation ,onInvalidate=""}) => {
  const queryClient = useQueryClient();
  //const id = row.original.website_offer_id;
  const handleArchive = (data) => {
   
    epcArchive.mutate(data);
    handleClose();
  };
  const epcArchive = useMutation(deleteMutation, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      onInvalidate();
    },
  });
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Once you delete this data, you won't be able to recover it. Are
            you sure you want to go ahead?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary text-uppercase" onClick={handleClose} >
            Close
          </Button>
          <Button variant="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 btn btn-primary" onClick={() => handleArchive(rowDataId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


   
    </>
  );
};

export default CommonDeleteModal;
