import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { insertReleaseNotes, updateReleaseNote } from '../services/service';
import { useQuery, useMutation, useQueryClient } from "react-query";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const releaseNoteSchema = Yup.object({
    version: Yup.string().trim().required("Please enter release version"),
    releaseDes: Yup.string().trim().required("Please enter release note description"),

});



function ReleaseNotesModal({ show, handleClose, editReleaseId = '', invalidateData = '', releaseNoteData = [] }) {

    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const editReleaseNote = releaseNoteData?.find((opt) => opt.id === editReleaseId) || []

    const initialValues = {
        id: editReleaseNote?.id || "",
        version: editReleaseNote?.version || "",
        releaseDes: editReleaseNote?.releaseDes || "",
        createAtDate: editReleaseNote?.createdAt || ""
    };
    const { values, handleBlur, handleChange, handleSubmit, setFieldValue, setValues, errors, touched, resetForm } =
        useFormik({
            initialValues: initialValues,
            validationSchema: releaseNoteSchema,
            onSubmit: (values, action) => {

                if (editReleaseId != null) {
                    updateProjectMutation.mutate({ editReleaseId, values });
                    handleClose();
                } else {
                    addnewprojectMutation.mutate(values);
                    handleClose();
                }
            },
        });
    //form post
    const addnewprojectMutation = useMutation(insertReleaseNotes, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            invalidateData();
            // setIsLoading(false);       
            toast.dismiss();
            toast.success(data.message);
            resetForm();
            //          setValues({version:""});
            // setFieldValue("releaseDes","")
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });

    const updateProjectMutation = useMutation(updateReleaseNote, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            invalidateData();
            //  queryClient.resetQueries();
            toast.dismiss();
            toast.success(data.message);
            resetForm();
            //         setValues({version:""});
            // setFieldValue("releaseDes","")
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    const handleCloseModal = () => {
        // setValues({version:""});
        // setFieldValue("releaseDes","")
        resetForm();
        handleClose();
    }
    // useEffect(() => {
    //     setValues(initialValues);
    // }, [editReleaseId]);
    // useEffect(() => {
    //     setValues({version:initialValues?.version });
    //     setValues({version:initialValues?.version });
    //     setValues({version:initialValues?.version });
    //     // setFieldValue({ ...initialValues });
    // }, [editReleaseId, setValues,editReleaseNote?.length]);
    useEffect(() => {
        if (editReleaseNote) {
            const newValues = {
                id: initialValues?.id || "",
                version: initialValues?.version || "",
                releaseDes: initialValues?.releaseDes || "",
                createAtDate: initialValues?.createAtDate || "",
            };

            setValues(newValues);
        }
    }, [editReleaseId, setValues, editReleaseNote?.length]);

    const quillModules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            ["clean"],
        ],
    };
    console.log("editReleaseNote?.version", editReleaseNote?.version)
    console.log("editReleaseNote", editReleaseNote)
    console.log("initialValues", initialValues)
    console.log("After setValues:", (values));
    return (
        <>
            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editReleaseId ? 'Edit Release Note' : 'Add Release Note'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="py-4">
                        <form>
                            <div className="form-group mb-4">
                                <label className="form-label">Release Note Version</label>
                                <span className="mandatory">*</span>
                                <input
                                    type="text"
                                    className="form-control py-3 shadow-none px-4"
                                    name="version"
                                    placeholder="Enter release version"
                                    // value={values?.version}
                                    value={values.version}
                                    onChange={handleChange}
                                    maxLength={50}
                                    onBlur={handleBlur}
                                />
                                {errors.version && touched.version && (
                                    <p className="form-error text-danger">
                                        {errors.version}
                                    </p>
                                )}
                            </div>
                            <div className="form-group mb-4">
                                <label className="form-label">Release Date</label>
                                {/* <span className="mandatory">*</span> */}
                                <input
                                    type="date"
                                    className="form-control py-3 shadow-none px-4"
                                    name="createAtDate"
                                    placeholder="Enter release"
                                    // value={values?.version}
                                    selected={values.createAtDate}
                                    value={values.createAtDate}
                                    onChange={handleChange}
                                    maxLength={50}
                                    onBlur={handleBlur}
                                />
                                {/* {errors.version && touched.version && (
                            <p className="form-error text-danger">
                              {errors.version}
                            </p>
                          )} */}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Release Note Description</label>
                                {/* <textarea
                                    className="form-control py-3 shadow-none px-4"
                                    name="releaseDes"
                                    placeholder="Enter Release Description"

                                    value={values?.releaseDes}
                                    onChange={handleChange}
                                /> */}
                                <ReactQuill
                                    theme="snow"
                                    value={values?.releaseDes || ""}
                                    // onChange={handleChange}
                                    onChange={(content) => setFieldValue('releaseDes', content)}
                                    style={{ height: "200px", marginBottom: "20px" }}
                                    modules={quillModules}
                                />
                                {errors.releaseDes && touched.releaseDes && (
                                    <p className="form-error text-danger">
                                        {errors.releaseDes}
                                    </p>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="save-menu mt-2 mb-2 text-end">
                        <Button
                            variant="primary"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            onClick={handleSubmit}
                        >
                            Save Note
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReleaseNotesModal
