import React, { useState } from "react";
import Table from "../../components/Table2";
import { getBookingEnquiryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../globalContext";
import { useContext } from "react";
const BookingEnquiry = () => {
    const { bwebsiteId } = useContext(GlobalContext);

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showMessages, setMessages] = useState("");

    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");

    const allBookingEnquiryData = useQuery(
        ["getBookingEnquiryData", +websiteIdFromCookie],
        () => getBookingEnquiryData(websiteIdFromCookie)
    );

    const handleViewMessageModal = (message) => {
        setMessages(message);

        setShowMessageModal(true);
    };
    const closeMessageModal = () => setShowMessageModal(false);

    let columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },

            {
                Header: "Name",
                Cell: ({ row }) => `${row.original.name}`,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ row }) => `${row.original.email}`,
            },
            {
                Header: "Phone Number",
                accessor: "phone_no",
                Cell: ({ row }) => `${row.original.phone_no}`,
            },
            {
                Header: "Villa Type",
                accessor: "villa_type",
                Cell: ({ row }) => `${row.original.villa_type===null?"":row.original.villa_type}`,
            },
            {
                Header: "Rooms",
                accessor: "rooms",
                Cell: ({ row }) => `${row.original.rooms===null?"":row.original.rooms}`,
            },           
                       
            {
                Header: "Adult Counts",
                accessor: "adult",
                Cell: ({ row }) => `${row.original.adult===null?"":row.original.adult}`,
            },
            {
                Header: "Children Counts",
                accessor: "children",
                Cell: ({ row }) => `${row.original.children===null?"":row.original.children}`,
            },
            {
                Header: "Discount Code",
                accessor: "discount_code",
                Cell: ({ row }) => `${row.original.discount_code===null?"":row.original.discount_code}`,
            },
            {
                Header: "IATA Code",
                accessor: "iata_code",
                Cell: ({ row }) => `${row.original.iata_code===null?"":row.original.iata_code}`,
            },
            {
                Header: "Booking Start Date",
                accessor: "booking_start_date",
                Cell: ({ row }) =>
                    moment(row.original.booking_start_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            },
            {
                Header: "Booking End Date",
                accessor: "booking_end_date",
                Cell: ({ row }) =>
                    moment(row.original.booking_end_date).format("MMM D, YYYY h:mm A [GMT]Z"),
            }, 
            {
                Header: "Created on",
                accessor: "created_on",
                Cell: ({ row }) =>row.original.created_on ===null?"":
                    moment(row.original.created_on).format("MMM D, YYYY h:mm"),
            },           
          
        ],
        []
    );
    
  
    if (allBookingEnquiryData.status === "loading") {
        <div>Loading</div>;
    }

    if (!allBookingEnquiryData.data) {
        <div>Loading</div>;
    }
    if (allBookingEnquiryData.isLoading) {
        <div>Loading</div>;
    }
    const headerHeading = "All Booking Enquiries";

    return (
        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-header">
                            <h4 className="fw-normal m-0">Booking Enquiries</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5 mt-4">
                        {/* <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All Enquiries</h5>
                        </div> */}
                        {allBookingEnquiryData?.data &&
                            (allBookingEnquiryData?.isLoading || !allBookingEnquiryData?.data ? (
                                <div className="text-center">Loading...</div>
                            ) : allBookingEnquiryData?.data?.data?.length === 0 ? (
                                <div className="card-body text-center">No Data Available</div>
                            ) : (
                                <Table
                                    data={allBookingEnquiryData?.data?.data}
                                    key={allBookingEnquiryData?.data?.data?.booking_enq_id}
                                    columns={columns}
                                    isShowingHeading={true}
                                    isExcelDownloadBtnShow={false}
                                    headerHeading={headerHeading}
                                />
                            ))}
                    </div>
                </div>
            </div>

            {/* START  DIALOG MODAL */}

            <Modal show={showMessageModal} onHide={closeMessageModal}>
                <Modal.Header className="px-5 py-4" closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <p>{showMessages}</p>
                    <div className="text-end mt-4">
                        <Button
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            variant="secondary"
                            onClick={closeMessageModal}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* END  DIALOG MODAL */}
        </>
    );
};

export default BookingEnquiry;
